/* You can add global styles to this file, and also import other style files */
@import "variables";
@import 'ngx-toastr/toastr';

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "scss/form";

body {
  font-family: 'Familjen Grotesk', sans-serif;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.form-control.ng-touched.ng-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.skeleton-loader {
  margin-bottom: 0 !important;
}

.toast-container{
  position: fixed;
}
