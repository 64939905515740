$black: #0c0c0c;
$gray: #7a7a7a;
$light-gray: #b8b8b8;
$white: #ffffff;
$primary: #0e90b9;
$red-50: #fef2f2;
$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #fca5a5;
$red-400: #f87171;
$red-500: #ef4444;
$red-600: #dc2626;
$red-700: #b91c1c;
$red-800: #991b1b;
$red-900: #7f1d1d;
$red-950: #450a0a;
$yellow-50: #fef9e8;
$yellow-100: #fef0c3;
$yellow-200: #fee28a;
$yellow-300: #fdd147;
$yellow-400: #fac215;
$yellow-500: #eab308;
$yellow-600: #ca9a04;
$yellow-700: #a17c07;
$yellow-800: #85680e;
$yellow-900: #715a12;
$yellow-950: #423306;
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "black": $black,
    "gray": $gray,
    "light-gray": $light-gray,
    "white": $white,
    "danger": $red-500,
    "warning": $yellow-500,
    "red-50": $red-50,
    "red-100": $red-100,
    "red-200": $red-200,
    "red-300": $red-300,
    "red-400": $red-400,
    "red-500": $red-500,
    "red-600": $red-600,
    "red-700": $red-700,
    "red-800": $red-800,
    "red-900": $red-900,
    "red-950": $red-950,
    "yellow-50": $yellow-50,
    "yellow-100": $yellow-100,
    "yellow-200": $yellow-200,
    "yellow-300": $yellow-300,
    "yellow-400": $yellow-400,
    "yellow-500": $yellow-500,
    "yellow-600": $yellow-600,
    "yellow-700": $yellow-700,
    "yellow-800": $yellow-800,
    "yellow-900": $yellow-900,
    "yellow-950": $yellow-950,
  ),
  $theme-colors
);
