@import "../variables";
.icon {
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 18px;

  &.icon-user {
    background-image: url(../assets/images/icons/icon-user.svg);
  }

  &.icon-close {
    background-image: url(../assets/images/icons/close.svg);
  }
  &.icon-phone {
    background-image: url(../assets/images/icons/icon-phone.svg);
  }

  &.icon-email {
    background-image: url(../assets/images/icons/icon-email.svg);
  }
  &.icon-eye-black {
    background-image: url(../assets/images/icons/eye-black.svg);
  }
  &.icon-eye-white {
    background-image: url(../assets/images/icons/eye-white.svg);
  }
  &.icon-eyelash-black {
    background-image: url(../assets/images/icons/eyeslash-black.svg);
  }

  &.icon-magnifier {
    background-image: url(../assets/images/icons/icon-magnifier.svg);
    background-size: 15px;
  }
  &.icon-calendar {
    background-image: url(../assets/images/icons/icon-calendar.svg);
    background-size: 15px;
  }
  &.icon-link {
    background-image: url(../assets/images/icons/link.svg);
    background-size: 15px;
    background-position: left 10px center;
  }
  &.icon-arrow-left {
    background-image: url(../assets/images/icons/icon-arrow-left.svg);
    background-size: 15px;
    background-position: left 10px center;
    padding-left: 10px;

    &:hover {
      background-image: url(../assets/images/icons/icon-arrow-left-white.svg);
    }
  }
}

.input-password {
  position: relative;
  .input-password__icon {
    display: inline-block;
    background-image: url(../assets/images/icons/eye.svg);
    cursor: pointer;
    background-size: contain;
    height: 18px;
    width: 18px;
    background-position: right center;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  input[type="text"] + .input-password__icon {
    background-image: url(../assets/images/icons/eyeslash.svg);
  }
}

.btn-update {
  background-image: url(../assets/images/icons/documentdownload.svg);
  background-size: 25px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;

  &.btn-update--upload {
    background-image: url(../assets/images/icons/documentupload.svg);
    border: 1px solid $light-gray;
    border-radius: 0.25rem;

    &:hover {
      background-color: $light-gray;
      background-image: url(../assets/images/icons/documentuploadblack.svg);
    }
  }

  &.canDownload {
    background-image: url(../assets/images/icons/downloadactive.svg);
  }
  &.span-btn-update {
    margin-left: 20px;
  }
}

.btn-invoice {
  background-image: url(../assets/images/icons/documentdownload.svg);
  background-size: 25px;
  width: 45px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left;
  cursor: pointer;

  &.btn-invoice--upload {
    background-image: url(../assets/images/icons/documentupload.svg);
    border: 1px solid $light-gray;
    border-radius: 0.25rem;

    &:hover {
      background-color: $light-gray;
      background-image: url(../assets/images/icons/documentuploadblack.svg);
    }
  }

  &.canDownload {
    background-image: url(../assets/images/icons/downloadactive.svg);
  }
  &.hasInvoice {
    background-image: url(../assets/images/icons/documentuploaded.svg);
    border: 1px solid $primary;

    &:hover {
      background-color: $primary;
      background-image: url(../assets/images/icons/documentuploadedwhite.svg);
    }
  }
}

.btn-preference {
  background-image: url("../assets/images/icons/icon-preference-off.svg");
  background-size: 25px;
  width: 45px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;

  &.active {
    background-image: url(../assets/images/icons/icon-preference-on.svg);
  }
}

.btn-close {
  background-image: url("../assets/images/icons/close.svg");
  background-size: 25px;
  width: 45px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

//

.badge-primary {
  background-color: #109ac6;
  color: white;
  font-size: 15px;
}
.badge-black {
  background-color: $black;
  color: white;
  font-size: 15px;
}

.form-floating-select {
  label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    z-index: 1001;
  }
  .ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e9ecef;
  }
  .ng-select .ng-arrow-wrapper {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    .ng-arrow {
      border: none;
    }
  }
  .ng-select.ng-select-single .ng-select-container {
    min-height: 58px;
    border-radius: 6px;

    .ng-value-container .ng-input {
      top: 30px !important;
    }
  }
  .ng-select-container {
    height: 58px;
    border-radius: 6px;
  }

  .ng-value-container {
    margin-top: 20px !important;
  }
}

.form-input {
  background: #ffffff;
  border: 1px solid #b8b8b8;
  border-radius: 15px;
  font-size: 20px;
  height: 62px;
  display: block;
  padding: 10px;
  line-height: 1.15;
  cursor: pointer;

  &.label-file {
    border: 1px solid black;
    line-height: 2;
    background: #f5f5f5 url(../assets/images/icons/documentupload.png) no-repeat
      right 30px center;
    background-size: 30px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    padding-right: 70px;
  }

  &.textarea-input {
    height: 285px;
  }
}

.form-input--light {
  border-radius: 6px;

  &.label-file {
    border: 1px solid #ced4da;
    background-color: #ffffff;
    font-size: 16px;
    &:hover {
      border-color: #9b9fa3;
    }
  }
}
